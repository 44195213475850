.Button {
  background: white;
  padding: 8px 10px 8px 10px;
  border: 1px solid white;
}

.ButtonActive, .Button:hover {
  background: cyan;
}

.Toolbar {
  padding: 0px 5px 0px 5px;
  display: flex;
  justify-content: flex-start;
  background: white;
  margin-bottom: 5px;
  height: 100%;
}
