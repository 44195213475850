
.container {
  margin-top: 1em;
  background-color: lightgrey ;
  display: flex;
  height: 175px;
}

.Content {
  margin: 8px;
}


.ListView {
  margin: 10px;
  padding: 5px;
  height: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
 background-color: lightyellow;
}
.deckTitle {
  display: flex;
  justify-content: space-between;
}
.deckCard {
  /* margin: 10px; */
  /* width: 75%; */
  padding: 5px;
  display: flex;
  flex-flow: column;
  /* font-size: 0.9rem; */
 background-color: rgb(199, 199, 191);
}
.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imgCard{
  /* width:25%; */
  height: 100%;
  background-color: azure;

  padding: 4px;
}
.Expand {
  margin-left: auto;
}
.cardView > * {
  width: 100%;
  height: 20%;
  text-align: 'left';
  margin-bottom: 3px;
}
