div.DraftEditor-root {
  /* border: 1px solid #000; */
  border-radius: 2px;
  opacity: .8;
  padding: 2px;
  height: 100%;
  width: 100%;
  /* background-color: lightgreen; */


}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
padding: 10px;
height: 100%;
width: 100%;
text-align: left;
overflow-y: auto;
}
div.public-DraftEditor-content:focus{
background-color: ivory;
}
.unstyled {
  padding: 2px;
  /* font-size: 12px; */
}
div.unstyled:focus{
}

.focusedBlock {
  background-color: white;
  color: green;
  padding: 10px;
}
.newBlock {
  background-color: blue;
  color: blue;
  padding:10px;
}
.unFocusedBlock {
  color: red;
  padding: 10px;
}
.lastBlock {
  background-color: azure;
  border-bottom: 2px lightblue solid;
  height: 8px;
}
.headerOne{
  /* font-size: 0.9rem; */
  line-height: normal;

}