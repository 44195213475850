.containerOuter{
  position: absolute;
  width: 600px;
  height: 200px;
  bottom: 10px;
  left: 30vw;
  box-shadow: 5px tomato;
  /* padding: 8px 20px; */
  border-radius: 100px ;
  /* border: 1px solid maroon; */
  opacity: 0.9;
  /* background-color: rgb(233, 233, 214); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftSemi{
  height: 100%;
  width: 25%;
  border-radius:  125px 0px 0px 125px;
  background-color: #3e88a5;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity:1;
}
.rightSemi{
  /* opacity: 1; */
  height: 100%;
  width: 25%;
  border-radius: 0px 125px 125px 0px;
  background-color: #3e88a5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.largeIcon {
  background-color: ivory;
  border: 1px solid blue;
  /* height: 50px;
  width: 50px; */
}

.iconRoot {
  /* opacity: 1; */
  transform: scale(1.9);
  color: rgb(226, 226, 202);
}

.CardEditor {
  height: 100%;
}

.NewNote {
  padding: 5px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 95%;
  width: 75%;
  border: 1px solid yellow;
  background-color: #dcdcdc;
}
.captureDiv {
  position: absolute;
  width: 360px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: 80vh;
  left: 40vw;
  background-color: lightgray;

}
.buttonCapture {
  height: 100%;
  width: 100%;
  border: 2px solid lightblue;
  display: flex;
  justify-content: flex-start;
}
.buttonCapture > * {
  margin: 0px 25px;
  font-size: large;
}
.captureIcon {
 height: 40px;
 width: 40px;

}
.NewNoteMax {
  position: absolute;
  width: 50vw;
  height: 50vh;
  top: 40vh;
  left: 25vw;
}

.SaveBar {
  width: 100%;
  display: flex;
  padding: 5px;
  height: 30px;
  justify-content: flex-end;
  align-content: center;
}
.ChooseDeck {
padding: 0px 5px 0px 35px;
background-color: ivory;  
border-radius: 10px;
display: flex;
align-items: center;
font-size: 0.8em;
width: 50%;
}
