
:root {
  font-size: calc(0.60em + 0.5vw);
}

/* @media (min-width: 800px) {
  :root {
    font-size: 0.875em;
  }
}

@media (min-width: 1200px) {
  :root {
    font-size: 1em;
  }
} */

.dark {
  margin-top: 2em;
  padding: 1em;
  background-color: #999;
  --main-bg: #333;
  --main-color: #fff;
}
.theme-light {
   --color-primary: #0060df;
   --color-secondary: #fbfbfe;
   --color-accent: #fd6f53;
   --font-color: #000000;
}
.theme-dark {
   --color-primary: #17ed90;
   --color-secondary: #243133;
   --color-accent: #12cdea;
   --font-color: #ffffff;
}

body {
  margin: 0;
  width: 100%;
  line-height: 1.5em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  scrollbar-width: thin;
  scrollbar-color: transparent;
  scroll-behavior: smooth;
  box-sizing: border-box;
}
*,
::before,
::after {
  box-sizing: border-box;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 10px;
  border: 1px solid orange;
}
