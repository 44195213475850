.CardEditor {
  height: 90%;
  width: 100%;
  background-color: cyan;
}

.fixedHeight {
  height: 86vh
}

.leftMargin{
  margin-left: 60px;

}
.ContentBox {
  background-color: blue;
  height: calc(100vh);
  width: 100%;
  padding: 2px;
}

.DeckNames {
  background-color: lightgray;
  height: 100%;
}
.DeckEditorContainer {
  background-color: white;
  width: 100%;
  padding: 2px;
  height: 100%;
  display: flex;
}

.EditorContainterOuter {
  /* border: 1px solid black; */
  border-radius: 2px;
  width: 100%;
  background-color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-right: 15px;
  box-shadow: 2px 2px 2px lightgray, -2px 2px 2px  lightgray;
}
.EditorContainer {
  composes: fixedHeight;
  border-radius: 1px;
  width: 99%;

  overflow-y: auto;
  background-color: white;
}
.Gist {
  height: 85vh;
  background-color: ghostwhite;
  overflow-y: auto;
}
.explorer {
  overflow-y: auto;
  box-shadow: 2px 2px 2px lightgray, -2px 2px 2px  lightgray;
}
.tocButton{
  height: 24px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 30px;
}
.SideDrawer {
  composes: fixedHeight;
  background-color: white;
  border-radius: 0.5em;
  padding: 2px;
  margin-right: 15px;
  box-shadow:2px 2px 3px lightgray;
  /* overflow-y: auto; */
  display: flex;
  flex-direction: column;
}
.ReviewCardEditor {
  background-color: green;
  height: 50vh;
  width: 60vw;
}
.ReviewCardTitles {
  height: 30px;
  width: 100px;
  display: flex;
  flex-direction: row;
}
.DeckCard {
  margin: 10px;
  padding: 5px;
  height: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
 background-color: light-yellow;
}
