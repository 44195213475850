.Card {
  background-color: #ddd;
  min-height: 300px;
}
.Footer {
  background-color: #0a0;
  height: 10px;
}

.Card:hover{
  background-color: #ddd;
}
.rounded {
  width: 40rem;
  height: 25rem;
  border: none;
  border-radius: 1.5rem;
  box-shadow: 2.5px 5px 15px rgba(0, 0, 0, 0.25);
}

.dashed {
  border-width: 3px;
  border-style: dashed;
}

.transparent {
  background: transparent;
}

.instructions {
  width: 45rem;
  height: 80vh;
  border: none;
  border-radius: 1.5rem;
  box-shadow: 2.5px 5px 15px rgba(0, 0, 0, 0.25);
  padding: 1em 1em 1em 1.75em;
}

.credits {
  width: 35rem;
  height: 55vh;
  border: none;
  border-radius: 1.5rem;
  box-shadow: 2.5px 5px 15px rgba(0, 0, 0, 0.25);
  padding: 1em 1em 1em 1.75em;
}
